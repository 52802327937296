import { ChangeEvent, useState } from "react";

import { Box, Text } from "@chakra-ui/react";

import axios from "axios";
import Logo from "../../components/Logo";
import { ICompany } from "../../types/Stock";
import CompanySearch from "./CompanySearch";

export function HeroTopBar() {
  const [searchValue, setSearchValue] = useState("");
  const [stocks, setStocks] = useState<ICompany[]>([]);
  const [cache, setCache] = useState(new Map<string, ICompany[]>());

  const fetchSearchStocks = async (searchValue: string) => {
    if (searchValue === "") return;

    if (cache.has(searchValue)) {
      setStocks(cache.get(searchValue) as ICompany[]);
      return;
    }

    const res = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/stocks`,
      {
        params: { limit: 20, search: searchValue },
      },
    );

    const filteredData = res.data.data.filter(
      (e: ICompany, i: number) =>
        res.data.data.findIndex((a: ICompany) => a.symbol === e.symbol) === i &&
        i <= 5,
    );

    setCache((prevCache) => {
      const newCache = new Map(prevCache);
      newCache.set(searchValue, filteredData);
      return newCache;
    });
    setStocks(filteredData);
  };

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchValue(newValue);

    if (newValue === "") {
      setStocks([]);
      return;
    }

    fetchSearchStocks(newValue);
  };

  const clearSearch = () => {
    setSearchValue("");
    setStocks([]);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      height="100%"
      fontFamily="Rubik"
      mt={"10px"}
      padding={"10px 60px 20px 60px"}
    >
      <Box mt="20px" mb="28px" color="#fff">
        <Logo />
      </Box>

      <Box
        width={"100%"}
        height={"100%"}
        mb="28px"
        color="#fff"
        textAlign={"center"}
        display="flex"
        flexDirection={"column"}
        justifyContent={"space-around"}
        alignItems={"center"}
      >
        <Text
          color="white"
          fontSize={{ lg: "20px", xl: "24px" }}
          fontWeight={"400"}
          lineHeight={"29px"}
          noOfLines={1}
          align="center"
          fontFamily="Rubik"
        >
          Discover market beating stocks
        </Text>
        <Text
          color="white"
          fontSize={{ lg: "20px", xl: "24px" }}
          fontWeight={"400"}
          lineHeight={"29px"}
          align="center"
          fontFamily="Rubik"
          noOfLines={1}
          mb="20px"
        >
          Powered by data science.
        </Text>

        <CompanySearch
          heroStyle={true}
          searchValue={searchValue}
          onChange={onChange}
          stocks={stocks}
          clearSearch={clearSearch}
        />

        <Text
          fontWeight={"400"}
          lineHeight={"29px"}
          fontSize="16px"
          mt="20px"
          mb="20px"
          color="#fff"
        >
          ...or start exploring below
        </Text>
      </Box>
    </Box>
  );
}
